export const excludeComponentProps = (
  neededProps: Record<string, unknown>,
  excludeProps: string[],
) => {
  return Object.keys(neededProps).reduce((acc, key) => {
    if (!excludeProps.includes(key)) {
      acc[key] = neededProps[key];
    }

    return acc;
  }, {} as Record<string, unknown>);
};
