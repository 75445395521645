import {
  TRequest,
  TRequestType,
  TRequestTableColumns,
  useRejectRequestMutation,
} from '@entities/requests';
import { IRequestDepositTableColumns } from '@entities/requests/deposit';
import { IRequestWithdrawalTableColumns } from '@entities/requests/withdrawal';
import { trafficTypes } from '@entities/traffic';
import { TUserRoles, useUserRole } from '@entities/user';
import HighlightIcon from '@features/highlight-icon';
import ReduceAndCopyString from '@features/reduce-and-copy-string';
import { ITableActionsConfig, TableActionBtns } from '@features/table-action-btns';
import TableCheckAllRows from '@features/table-check-all-rows';
import TableCheckOneRow from '@features/table-check-one-row';
import { FilterDateRange, FilterInput, FilterSelect, ResetFilterBtn } from '@features/table-filter';
import { getSelectOptions } from '@shared/components/Form';
import RequestStatusLabel from '@shared/components/RequestStatusLabel';
import { formatAmount } from '@shared/utils/formatAmount';
import formatSecondsToTime from '@shared/utils/formatSecondsToTime';
import { removeKeysFromObject } from '@shared/utils/removeKeysFromObject';

import { ApproveRequest } from '../components/ApproveRequest';
import { ConfirmApproveRejectRequest } from '../components/ConfirmApproveRejectRequest';
import { ViewRequest } from '../components/ViewRequest';

interface IProps {
  type: TRequestType;
  refetch?: () => void;
}

export const useTableSettings = ({ type, refetch }: IProps) => {
  const userRole = useUserRole();

  // THead settings
  const depositColumnTitles: IRequestDepositTableColumns = {
    id: 'Request ID',
    order_id: 'Order ID',
    method: 'Method',
    account: 'Account',
    merchant_short_name: 'Merchant Short Name',
    merchant_order_id: 'Merchant order ID',
    created_at: 'Request Date',
    updated_at: 'Update Date',
    processing_time: 'Processing Time',
    traffic: 'Traffic',
    merchant_customer_id: 'Merchant User ID',
    requested_amount: 'Requested Amount',
    actual_amount: 'Actual Amount',
    merchant_wallet: 'Merchant Wallet',
    customer_transaction_id: 'UTR',
    status: 'Status',
    amount_mismatch: 'Amount Mismatch',
    status_reason: 'Status Reason',
  };

  const withdrawalColumnTitles: IRequestWithdrawalTableColumns = {
    id: 'Request ID',
    order_id: 'Order ID',
    method: 'Method',
    account: 'Account',
    merchant_short_name: 'Merchant Short Name',
    customer_bank_account: 'Bank account',
    customer_bank_code: 'IFSC code',
    customer_account_name: 'Beneficiary',
    created_at: 'Request Date',
    updated_at: 'Updated Date',
    processing_time: 'Processing Time',
    phone: 'Phone',
    traffic: 'Traffic',
    merchant_customer_id: 'Merchant User ID',
    requested_amount: 'Requested Amount',
    actual_amount: 'Actual Amount',
    customer_transaction_id: 'UTR',
    human_hash: 'Human Hash',
    status: 'Status',
    amount_mismatch: 'Amount Mismatch',
    status_reason: 'Status Reason',
  };

  const hiddenColumnKeys: (keyof TRequestTableColumns)[] = [
    'id',
    'order_id',
    'method',
    'merchant_order_id',
    'account',
    'traffic',
  ];

  type TColumnTitles = {
    [role in TRequestType]: {
      [type in TUserRoles]:
        | Partial<IRequestDepositTableColumns>
        | Partial<IRequestWithdrawalTableColumns>;
    };
  };
  const getColumnTitles = (type: TRequestType, userRole: TUserRoles) => {
    if (!type || !userRole) return {} as TRequestTableColumns;

    const columnTitles: TColumnTitles = {
      deposit: {
        admin: depositColumnTitles,
        merchant: depositColumnTitles,
        operator: depositColumnTitles,
        provider: removeKeysFromObject(depositColumnTitles, ['traffic']),
      },
      withdrawal: {
        admin: withdrawalColumnTitles,
        merchant: withdrawalColumnTitles,
        operator: withdrawalColumnTitles,
        provider: removeKeysFromObject(withdrawalColumnTitles, ['traffic']),
      },
    };

    return columnTitles[type][userRole] as TRequestTableColumns;
  };

  const filterStatuses = [
    'pending',
    'new',
    'approved',
    'rejected',
    'mismatch',
    'invalid',
    'expired',
  ];

  const filterAmountMismatch = [
    { id: 'true', label: 'Yes' },
    { id: 'false', label: 'No' },
  ];

  const allowedStatusesSingleAction = ['pending', 'new', 'mismatch', 'invalid', 'expired'];
  const allowedStatusesBulkAction = ['pending', 'new', 'mismatch', 'invalid', 'expired'];

  const renderFilterElement = (key: keyof TRequest) => {
    switch (key) {
      case 'status':
        return <FilterSelect name={key} options={getSelectOptions(filterStatuses)} />;

      case 'amount_mismatch':
        return <FilterSelect name={key} options={filterAmountMismatch} />;

      case 'traffic':
        return <FilterSelect name={key} options={getSelectOptions(trafficTypes)} />;

      case 'created_at':
      case 'updated_at':
        return <FilterDateRange name={key} />;

      default:
        return <FilterInput name={key} />;
    }
  };

  const getTheadBefore = (requests: TRequest[]) => (
    <th className='hidden-on-mob'>
      <TableCheckAllRows items={requests} filterKeys={allowedStatusesBulkAction} />
    </th>
  );
  const getTheadAfter = (requests: TRequest[]) => (
    <th>
      <ResetFilterBtn />
    </th>
  );

  // TBody settings

  const renderRow = (key: keyof TRequestTableColumns, request: TRequest) => {
    switch (key) {
      case 'id':
      case 'order_id':
        return <ReduceAndCopyString text={request[key]} />;

      case 'customer_transaction_id':
        return <ReduceAndCopyString text={request[key]} needReduce={false} />;

      case 'processing_time':
        return formatSecondsToTime(request[key]);

      case 'actual_amount':
      case 'requested_amount':
        return request[key] && `${formatAmount(String(request[key]))} ${request.currency}`;

      case 'status':
        return <RequestStatusLabel status={String(request[key])} />;

      case 'amount_mismatch':
        return <>{request[key] && <HighlightIcon text='Amount mismatch' />}</>;

      default:
        return request[key as keyof TRequest];
    }
  };

  const getTbodyBefore = (request: TRequest) => {
    const isAllowedStatusBulkAction = allowedStatusesBulkAction.includes(
      request.status.toLowerCase(),
    );

    return (
      <td>
        <TableCheckOneRow id={request.id} isDisabled={!isAllowedStatusBulkAction} />
      </td>
    );
  };
  const getTbodyAfter = (request: TRequest) => {
    const isAllowedStatusSingleAction = allowedStatusesSingleAction.includes(
      request.status.toLowerCase(),
    );

    const actionBtnsConfig: ITableActionsConfig = {
      reject: {
        isEnabled: isAllowedStatusSingleAction,
        isVisible: true,
        popupElem: (
          <ConfirmApproveRejectRequest
            data={{ id: request.id, reason: '' }}
            message='Do you want to reject this request?'
            mutation={useRejectRequestMutation}
            mutationErrorName='request'
            refetch={refetch}
          />
        ),
        popupSize: 'sm',
        allowedRoles: ['admin', 'operator'],
      },
      view: {
        isEnabled: true,
        isVisible: true,
        popupElem: <ViewRequest id={request.id} type={type} />,
        popupSize: 'lg',
        allowedRoles: ['admin', 'operator'],
      },
      approve: {
        isEnabled: isAllowedStatusSingleAction,
        isVisible: true,
        popupElem: <ApproveRequest request={request} refetch={refetch} />,
        popupSize: 'sm',
      },
    };

    return (
      <td className='action-btns-row'>
        <TableActionBtns config={actionBtnsConfig} />
      </td>
    );
  };

  return {
    columnTitles: getColumnTitles(type, userRole),
    hiddenColumnKeys,

    renderFilterElement,
    getTheadBefore,
    getTheadAfter,

    renderRow,
    getTbodyBefore,
    getTbodyAfter,
  };
};
