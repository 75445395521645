import {
  INewProviderQuery,
  IProvider,
  IProviderTableColumns,
  useCreateProviderMutation,
  useDeleteProviderMutation,
  useEditProviderMutation,
} from '@entities/provider';
import { ModalDeleteEntity } from '@features/modal-entity-delete';
import { ModalEditEntity } from '@features/modal-entity-edit';
import { ModalNewEntity } from '@features/modal-entity-new';
import { ModalViewEntity } from '@features/modal-entity-view';
import { ITableActionsConfig, TableActionBtns } from '@features/table-action-btns';

interface IProps {
  refetch?: () => void;
}

export const useTableSettings = ({ refetch }: IProps) => {
  const [deleteProvider] = useDeleteProviderMutation();

  // THead settings
  const columnTitles: IProviderTableColumns = {
    name: 'Name',
    email: 'Email',
    company_name: 'Company Name',
    address: 'Address',
    country: 'Country',
    mobile: 'Mobile',
    contact_person: 'Contact Person',
    created_at: 'Created At',
    updated_at: 'Updated At',
    short_name: 'Short Name',
    actions: 'Actions',
  };

  // TBody settings
  const renderRow = (key: keyof IProviderTableColumns, provider: IProvider) => {
    switch (key) {
      case 'actions': {
        const excludeKeys: (keyof IProvider)[] = [
          'id',
          'created_at',
          'updated_at',
          'deleted_at',
          'email_verified_at',
        ];

        const actionBtnsConfig: ITableActionsConfig = {
          edit: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalEditEntity
                title='Edit Provider'
                entity={provider}
                excludeKeys={excludeKeys}
                useEditEntityMutation={useEditProviderMutation}
                refetch={refetch}
              />
            ),
            popupSize: 'md',
          },
          view: {
            isEnabled: true,
            isVisible: true,
            popupElem: <ModalViewEntity entity={provider} title='View Provider' />,
            popupSize: 'md',
          },
          delete: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalDeleteEntity
                id={provider.id}
                message='Do you want to delete this provider?'
                deleteEntity={deleteProvider}
                refetch={refetch}
              />
            ),
            popupSize: 'sm',
          },
        };

        return <TableActionBtns config={actionBtnsConfig} />;
      }

      default:
        return provider[key];
    }
  };

  // New provider settings
  const newProviderKeys: (keyof INewProviderQuery)[] = ['name', 'email', 'password', 'short_name'];
  const NewProvider = () => (
    <ModalNewEntity
      title='New Provider'
      entityKeys={newProviderKeys}
      useCreateEntityMutation={useCreateProviderMutation}
      refetch={refetch}
    />
  );

  return {
    columnTitles,
    renderRow,
    NewProvider,
  };
};
