import { FC } from 'react';

import Skeleton from 'react-loading-skeleton';

import { useBanks } from '@entities/bank';
import ErrorMessage from '@shared/components/ErrorMessage';
import { Select } from '@shared/components/Form';

import 'react-loading-skeleton/dist/skeleton.css';

interface IProps {
  onChangeBank?: (bankId: string) => void;
}

export const BanksSelect: FC<IProps> = ({ onChangeBank }) => {
  const { banks, isLoading, isFetching, error } = useBanks({
    disableUrlFilter: true,
  });

  if (isLoading) return <Skeleton height={42} />;
  if (error) {
    return (
      <>
        <select></select>
        <ErrorMessage error={error} />
      </>
    );
  }

  if (banks) {
    return (
      <Select
        name='bank_id'
        options={banks.data}
        getOptionValue={bank => bank.id}
        getOptionLabel={bank => bank.bank_validator_name}
        onChange={onChangeBank}
        isDisabled={isFetching}
      >
        <option value=''>Select bank</option>
      </Select>
    );
  }

  return null;
};
