import { ChangeEvent, useState, useEffect, useRef } from 'react';

import debounce from 'lodash.debounce';

import { TTableColumnsFilter, useTableContext } from '@shared/components/Table';

interface IProps<T> {
  name: keyof T;
}

export const FilterInput = <T,>({ name }: IProps<T>) => {
  const [inputValue, setInputValue] = useState<string>('');

  const { currentFilter, updateFilter } = useTableContext<T>();

  const filterKey = `filter[${String(name)}]`;

  const debouncedHandleFilter = useRef(
    debounce((value: string) => {
      updateFilter({ [filterKey]: value } as TTableColumnsFilter<T>);
    }, 500),
  ).current;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
    debouncedHandleFilter(value);
  };

  useEffect(() => {
    setInputValue(currentFilter.has(filterKey) ? currentFilter.get(filterKey) : '');
  }, [currentFilter, filterKey]);

  return (
    <input
      type='text'
      placeholder='Search'
      data-filter-term={filterKey}
      onChange={handleChange}
      value={String(inputValue)}
    />
  );
};
