import { ReactNode } from 'react';

import { TTransaction, TTransactionType, TTransactionTableColumns } from '@entities/transactions';
import { ITransactionDepositTableColumns } from '@entities/transactions/deposit';
import { ITransactionWithdrawalTableColumns } from '@entities/transactions/withdrawal';
import { FilterDateRange, FilterInput, FilterSelect, ResetFilterBtn } from '@features/table-filter';
import RequestStatusLabel from '@shared/components/RequestStatusLabel';
import { formatAmount } from '@shared/utils/formatAmount';

interface IProps {
  type: TTransactionType;
  refetch?: () => void;
}

export const useTableSettings = ({ type, refetch }: IProps) => {
  // THead settings
  const depositColumnTitles: ITransactionDepositTableColumns = {
    id: 'Transaction ID',
    order_id: 'Order ID',
    wallet: 'Wallet',
    bank_name: 'Bank Name',
    utr: 'UTR',
    amount: 'Amount',
    status: 'Status from bank',
    external_reference: 'External Reference',
    created_at: 'Created Date',
    updated_at: 'Updated Date',
  };

  const withdrawalColumnTitles: ITransactionWithdrawalTableColumns = {
    id: 'Transaction ID',
    order_id: 'Order ID',
    wallet: 'Wallet',
    bank_name: 'Bank Name',
    utr: 'UTR',
    amount: 'Amount',
    status: 'Status from bank',
    external_reference: 'External Reference',
    created_at: 'Created Date',
    updated_at: 'Updated Date',
  };

  const getColumnTitles = (type: TTransactionType) => {
    switch (type) {
      case 'deposit':
        return depositColumnTitles;
      case 'withdrawal':
        return withdrawalColumnTitles;
    }
  };

  const filterStatuses = ['pending', 'new', 'success', 'failed'];
  const filterStatusesOptions = filterStatuses.map(status => ({
    id: status,
    label: status,
  }));

  const renderFilterElement = (key: keyof TTransaction) => {
    switch (key) {
      case 'status':
        return <FilterSelect name={key} options={filterStatusesOptions} />;

      case 'created_at':
      case 'updated_at':
        return <FilterDateRange name={key} />;

      default:
        return <FilterInput name={key} />;
    }
  };

  const getTheadBefore = (transactions: TTransaction[]): ReactNode => {
    return null;
  };
  const getTheadAfter = (transactions: TTransaction[]) => (
    <th>
      <ResetFilterBtn />
    </th>
  );

  // TBody settings
  const renderRow = (key: keyof TTransactionTableColumns, transaction: TTransaction) => {
    switch (key) {
      case 'amount':
        return (
          transaction[key] && `${formatAmount(String(transaction[key]))} ${transaction.currency}`
        );

      case 'status':
        return <RequestStatusLabel status={String(transaction[key])} />;

      default:
        return transaction[key];
    }
  };

  const getTbodyBefore = (transaction: TTransaction): ReactNode => {
    return null;
  };
  const getTbodyAfter = (transaction: TTransaction): ReactNode => {
    return <td></td>;
  };

  return {
    columnTitles: getColumnTitles(type),

    renderFilterElement,
    getTheadBefore,
    getTheadAfter,

    renderRow,
    getTbodyBefore,
    getTbodyAfter,
  };
};
