import { FC } from 'react';

import Select, { StylesConfig, CSSObjectWithLabel, ControlProps, MultiValue } from 'react-select';

interface ISelectMultiOption {
  value: string;
  label: string;
}

export type ISelectMultiOptions = ISelectMultiOption[] | MultiValue<ISelectMultiOption>;

interface IProps {
  name: string;
  options: ISelectMultiOptions;
  value?: MultiValue<ISelectMultiOption>;
  isDisabled?: boolean;
  onChange: (value: MultiValue<ISelectMultiOption>) => void;
}

const customStyles: StylesConfig<ISelectMultiOption, true> = {
  control: (provided: CSSObjectWithLabel, state: ControlProps<ISelectMultiOption, true>) =>
    ({
      ...provided,
      borderColor: state.isFocused ? '#4e97fd' : '#e3e9ef',
      borderRadius: '8px',
      minHeight: '44px',
    } as CSSObjectWithLabel),
};

export const SelectMulti: FC<IProps> = ({ name, options, value, isDisabled = false, onChange }) => {
  return (
    <Select
      name={name}
      isMulti
      options={options}
      classNamePrefix='select'
      styles={customStyles}
      value={value}
      onChange={onChange}
      isDisabled={isDisabled}
    />
  );
};
