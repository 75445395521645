import { RootState } from '@app/store';
import { useTypedSelector } from '@app/store/hooks/useTypedSelector';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUser, IUserResponse, TUserRoles } from '../model/types';

interface UserState {
  currentUser: IUser | null;
  hasLoggedAs: string;
}

const initialState: UserState = {
  currentUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  hasLoggedAs: localStorage.getItem('hasLoggedAs') || '',
};

const userSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<IUserResponse>) => {
      localStorage.setItem('user', JSON.stringify(action.payload.data));
      state.currentUser = action.payload.data;
    },
    clearCurrentUser: state => {
      localStorage.removeItem('user');
      state.currentUser = null;
    },
    setHasLoggedAs: (state, action: PayloadAction<string>) => {
      localStorage.setItem('hasLoggedAs', action.payload);
      state.hasLoggedAs = action.payload;
    },
    clearHasLoggedAs: state => {
      state.hasLoggedAs = '';
    },
  },
});

export const actions = userSlice.actions;
export default userSlice.reducer;

function getOperatorRole(state: RootState): TUserRoles {
  const isProvider =
    state.user.currentUser?.type === 'operator' && !state.user.currentUser.is_internal;

  return isProvider ? 'provider' : 'operator';
}

export const useCurrentUser = () => {
  return useTypedSelector((state: RootState) => state.user);
};

export const useUserRole = () => {
  return useTypedSelector((state: RootState) =>
    state.user.currentUser?.type === 'operator'
      ? getOperatorRole(state)
      : state.user.currentUser?.type,
  );
};

export const useHasLoggedAs = () => {
  return useTypedSelector((state: RootState) => state.user.hasLoggedAs);
};
