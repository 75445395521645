import { FC } from 'react';

import { NavLink, useLocation } from 'react-router-dom';

import type { IRoute } from '@app/routes';
import { routes } from '@app/routes';
import { TUserRoles, useUserRole } from '@entities/user';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import './nav.css';

interface IProps {
  setMobIsSidebarOpen: (value: boolean) => void;
}

export const Nav: FC<IProps> = ({ setMobIsSidebarOpen }) => {
  const { pathname } = useLocation();
  const userRole = useUserRole();

  const closeMobSidebar = () => {
    setMobIsSidebarOpen(false);
  };

  const isAccordionExpanded = (sublinks: IRoute[]) => {
    return sublinks.some(sublink => pathname.includes(sublink.url));
  };

  const filterLinks = (links: IRoute[], userRole: TUserRoles): IRoute[] => {
    return links.reduce((acc, link) => {
      if (link.isSkipNav) return acc;

      const sublinks = link.sublinks ? filterLinks(link.sublinks, userRole) : [];

      if (link.allowedRoles?.includes(userRole) || sublinks.length > 0) {
        acc.push({ ...link, sublinks });
      }

      return acc;
    }, []);
  };

  const filteredLinks = filterLinks(routes, userRole);

  return (
    <nav className='navbar'>
      <ul>
        {filteredLinks.map(link => {
          const expanded = link.sublinks ? isAccordionExpanded(link.sublinks) : false;

          return (
            <li
              key={link.name}
              className={`${link.sublinks ? 'has-subnav' : ''} ${
                link.isDisabled ? 'is-disabled' : ''
              }`}
            >
              {link.sublinks && link.sublinks.length > 0 ? (
                <Accordion defaultExpanded={expanded}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className='nav-link'>
                      {link.icon}
                      <span className='navlink-txt'>{link.name}</span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className='subnav'>
                      {link.sublinks.map(sublink => (
                        <li key={sublink.name} className={sublink.isDisabled ? 'is-disabled' : ''}>
                          <NavLink
                            to={sublink.url}
                            onClick={!sublink.isDisabled ? closeMobSidebar : undefined}
                          >
                            <span className='navlink-txt'>{sublink.name}</span>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <NavLink
                  to={link.url}
                  onClick={!link.isDisabled ? closeMobSidebar : undefined}
                  className={link.isDisabled ? 'is-disabled' : ''}
                >
                  {link.icon}
                  <span className='navlink-txt'>{link.name}</span>
                </NavLink>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
