import { FC } from 'react';

import CopyToClipboardButton from '@features/copy-to-clipboard-button';
import { reduceString } from '@shared/utils/reduceString';

interface IProps {
  text: string;
  needReduce?: boolean;
}

const ReduceAndCopyString: FC<IProps> = ({ text, needReduce = true }) => {
  if (!text) return null;

  return (
    <div className='table-cell-items' style={{ overflow: 'hidden' }}>
      {needReduce ? reduceString({ text }) : text}
      <CopyToClipboardButton text={text} />
    </div>
  );
};

export default ReduceAndCopyString;
