import { FC } from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { IconButton, Tooltip } from '@mui/material';

interface IProps {
  text: string;
}

const HighlightIcon: FC<IProps> = ({ text }) => {
  return (
    <Tooltip title={text}>
      <IconButton>
        <ErrorOutlineIcon />
      </IconButton>
    </Tooltip>
  );
};

export default HighlightIcon;
